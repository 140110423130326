import React, { useState, useEffect, useContext, Fragment } from "react";
import { useFormikContext } from "formik";
import { useFormikWizard } from "formik-wizard";
import * as _ from "lodash";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumber from "libphonenumber-js";
import "react-phone-input-2/lib/material.css";
import BottomModal from "../../../../common/components/bottomLoginModal/BottomModal";
import withErrorHandlerComponent from "../../../../hoc/withErrorHandler/withErrorHandlerComponent";
import ErrorAltIcon from "../../../../images/icons/errorAltIcon.svg";
import useWindowResize from "../../../../hooks/useWindowResize";
import { DeveloperDataContext } from "../../../../common/utils/appContext";
import { Append_0 } from "../../../../common/constants/Append_0";
import { otpLogin } from "../../../../common/constants/api";
import { navigate } from "gatsby";
import moment from "moment";

const EnterMobNo = props => {
  const [mobileNumber, setMobileNumber] = useState("");
  const Context = useContext(DeveloperDataContext);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldError,
    validateForm,
    setFieldTouched,
    resetForm,
    handleSubmit,
    isSubmitting,
    isValid,
  } = useFormikContext();
  const { values: wizardValues, status } = useFormikWizard();
  const [windowWidth] = useWindowResize();
  const url = new URL(window.location.href);
  useEffect(() => {
    validateForm();
  }, []);

  return (
    <div
      className={`w-full flex flex-col font-roboto justify-between pt-10 md:pt-14 pb-19 md:pb-4 mx-auto md:px-11 px-2 overflow-auto  ${
        windowWidth > 767 ? "" : " bg-white "
      }`}
    >
      <div className="md:px-0 px-4 mt-2">
        <div className="w-full mx-auto">
          <PhoneInput
            country={"in"}
            inputProps={{
              name: "mobile",
              required: true,
              autoFocus: false,
              style: {
                width: "100%",
                height: "52px",
                border: "1px solid #DCE9F5",
                "&:hover": {
                  border: "1px solid #00ACB8",
                },
                "&:focus": {
                  border: "1px solid #00ACB8",
                  boxShadow: "0 0 0 1px #00acb8",
                },
              },
            }}
            onlyCountries={[
              "in",
              "ae",
              "us",
              "ca",
              "gb",
              "sa",
              "qa",
              "sg",
              "hk",
              "my",
              "de",
              "it",
              "jp",
              "kw",
              "mv",
              "np",
              "lk",
              "th",
              "cn",
              "br",
              "bt",
              "au",
            ]}
            enableSearch
            countryCodeEditable={false}
            searchStyle={{
              width: "100%",
              maxWidth: "220px",
              height: "40px",
              "&:hover": {
                border: "1px solid #00ACB8",
              },
              "&:focus": {
                border: "1px solid #00ACB8",
              },
            }}
            dropdownStyle={{ width: "250px", maxWidth: "250px" }}
            onChange={(rawValue, countryData, event, formattedValue) => {
              let phoneNumber = parsePhoneNumber(
                formattedValue,
                countryData.countryCode
              );
              if (phoneNumber) {
                setFieldValue("mobile", phoneNumber.nationalNumber);
                setFieldValue("dialCode", countryData.dialCode);
                setFieldValue("countryCode", countryData.countryCode);
                setMobileNumber(formattedValue);
              }
            }}
            onBlur={(e, countryData) => {
              let phoneNumber = parsePhoneNumber(
                mobileNumber,
                countryData.countryCode
              );
              if (phoneNumber) {
                if (!phoneNumber.isValid()) {
                  setFieldError("mobile", "Please enter correct phone number");
                }
              }
            }}
            error={touched?.mobile && errors?.mobile}
            errorMsg={errors?.mobile}
            touched={touched?.mobile}
          />
        </div>
        {status?.message && (
          <div
            className="flex space-x-2 w-full p-3 px-4 items-center mt-4"
            style={{
              backgroundColor: "#FAEDE9",
            }}
          >
            <img src={ErrorAltIcon} alt="oops!" />
            <div className="text-black-400 text-sm">
              {withErrorHandlerComponent(status?.message)}
            </div>
          </div>
        )}
      </div>
      <div className="w-full md:mb-6 lg:mb-0 px-5 lg:px-0">
        <button
          id="submitPass"
          disabled={isSubmitting || !_.isEmpty(errors) || values?.mobile < 10}
          className={
            "block submitButton newButton w-full text-center" +
            ` h-13` +
            `${
              !(!_.isEmpty(errors) || values?.mobile?.length < 10)
                ? " cursor-pointer "
                : ` cursor-not-allowed opacity-25 `
            }`
          }
          onClick={() => {
            if (!(values?.mobile?.length < 10)) {
              let body = {
                phoneNumber: values?.mobile,
              };
              if (Context.appData.loginOtpError) {
                delete Context.appData.loginOtpError;
              }
            }
          }}
        >
          Request OTP
        </button>
        <p className="text-sm  my-6 text-grey-50">
          We will use your number to send OTP
        </p>

        <div className="text-center py-4 text-black-400">
          <span
            className="text-blue-500 cursor-pointer mr-1"
            onClick={() =>
              url.pathname.includes("bp") || url.pathname.includes("ifa")
                ? navigate("/")
                : location.reload()
            }
          >
            Login
          </span>
          with password instead.
        </div>
      </div>
    </div>
  );
};

export default EnterMobNo;
