export const GA_EVENTS = {
  PROCEED_CLICK: "PROCEED_CLICK",
  LOGIN_CLICK_RM: "LOGIN_CLICK_RM",
  LOGIN_CLICK_BP: "LOGIN_CLICK_BP",
  LOGIN_CLICK_CONSUMER: "LOGIN_CLICK_CONSUMER",
  LOGIN_CLICK_DEVELOPER: "LOGIN_CLICK_DEVELOPER",
  REGISTERED_BP: "REGISTERED_BP",
  PASSWORD_SET_BP: "PASSWORD_SET_BP",
  VERIFY_OTP_BP: "VERIFY_OTP_BP",
  REGISTERED_BP_FORM_FILLED: "REGISTERED_BP_FORM_FILLED",
  PAN_UPLOADED_BP: "PAN_UPLOADED_BP",
  BP_RERA_UPLOADED: "BP_RERA_UPLOADED",
  BP_GST_ADDED: "BP_GST_ADDED",
  BP_THANK_PAGE: "BP_THANKYOU_PAGE",
  SELECTED_BP_LOGIN: "SELECTED_BP_LOGIN",
  SELECTED_RM_LOGIN: "SELECTED_RM_LOGIN",
  SELECTED_DEV_LOGIN: "SELECTED_DEV_LOGIN",
  FORGOT_PASSWORD_CLICK: "FORGOT_PASSWORD_CLICK",
  PRIORITY_PASS_CREATED: "PRIORITY_PASS_CREATED",
  PRIORITY_PASS_SENT: "PRIORITY_PASS_SENT",
};
export function gtagTrackEvent(action) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action,
  });
}
