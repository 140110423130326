import React from "react";
import Eye from "../../../images/icons/eye (1).svg";
import CloseEye from "../../../images/icons/slashEye.svg";
export const PasswordInputField = props => {
  const showHidePassword = id => {
    var x = document.getElementById(id);

    if (x.type === "password") {
      x.type = "text";
      x.type = "text";
    } else {
      x.type = "password";
      x.type = "password";
    }
  };

  return (
    <label className="block lg:mt-8 relative mt-4">
      <span className=" block text-xs capitalize">{props.title}</span>
      <div className="">
        <div className="outer-div flex w-full ">
          <input
            className=" showHide flex-1 
            placeholder-grey-placeholder 
            form-input  
            rounded-sm
            mt-1  
            block w-full"
            type="text"
            id={props.name}
            name={props.name}
            placeholder="**********"
            onChange={e => {
              props.handleChange(e);
            }}
            onBlur={e => {
              props.handleBlur(e);
            }}
            value={props.password}
            onFocus={props.passwordValidation}
            required
            minLength="8"
            maxLength="15"
          />

          {/* showHidePassword */}
          <img
            className="absolute right-0 mt-2 h-8 w-10 pr-4"
            onClick={() => {
              showHidePassword(props.name);
              props.setShowEyeIcon();
            }}
            id="toggle"
            src={props.showEyeIcon ? CloseEye : Eye}
            alt="showPassword"
          />
        </div>
      </div>
    </label>
  );
};
