import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { useFormikWizard } from "formik-wizard";
import Error from "../../common/components/Error/Error";
import { PasswordInputField } from "../../common/components/PasswordInputField/PasswordInputField";
import ErrorAltIcon from "../../images/icons/errorAltIcon.svg";
import {
  passwordValidation,
  validationMessage,
} from "../PasswordValidation/PasswordValidation";
import { generatePassword } from "../../common/constants/GeneratePassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import withErrorHandlerComponent from "../../hoc/withErrorHandler/withErrorHandlerComponent";
import BottomModal from "../../common/components/bottomLoginModal/BottomModal";
import useWindowResize from "../../hooks/useWindowResize";
import { Tooltip } from "@material-ui/core";
const createPassword = props => {
  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    isValid,
    dirty,
    initialValues,
    isSubmitting,
    validateForm,
  } = useFormikContext();
  const { values: wizardValues, setStatus, status, wizard } = useFormikWizard();
  const [showEyeIcon, setShowEyeIcon] = useState(true);
  const [conFirmShowEyeIcon, SetConFirmShowEyeIcon] = useState(true);
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [copy, setCopy] = useState(false);
  const [windowWidth] = useWindowResize();

  const reGeneratePassword = () => {
    let password = generatePassword(10);
    setGeneratedPassword(password);
    setFieldValue("password", password || "");
    setFieldValue("confirmPassword", password || "");
  };
  const copyToClipboard = item => {
    navigator.clipboard.writeText(item).then(
      function () {
        setCopy(true);
        setTimeout(() => {
          setCopy(false);
        }, 1000);
      },
      function (err) {
        setCopy(false);
      }
    );
  };
  return (
    <BottomModal
      isLogin
      className={`w-full flex flex-col justify-between pt-4 md:pt-0 pb-19 md:pb-4 lg:max-w-md mx-auto px-2 md:overflow-hidden overflow-auto  ${
        windowWidth > 767 ? "" : " bg-white "
      }`}
      style={{
        height: windowWidth < 767 ? "" : "100%",
      }}
    >
      <div className=" relative pb-2 w-11/12 lg:w-10/12 max-w-sm mx-auto text-left ">
        <div className="">
          <div className="mt-5  ">
            <PasswordInputField
              title="Enter Password"
              handleChange={handleChange}
              handleBlur={handleBlur}
              name="password"
              password={values.password}
              passwordValidation={passwordValidation}
              showEyeIcon={showEyeIcon}
              setShowEyeIcon={() => setShowEyeIcon(!showEyeIcon)}
            />
            <Error touched={touched.password} message={errors.password} />
          </div>
          {/* {initialValues} */}
          <div className="">
            {/* //confirmPassword */}
            <PasswordInputField
              title="Confirm Password"
              handleChange={handleChange}
              handleBlur={handleBlur}
              name="confirmPassword"
              password={values.confirmPassword}
              showEyeIcon={conFirmShowEyeIcon}
              setShowEyeIcon={() => SetConFirmShowEyeIcon(!conFirmShowEyeIcon)}
            />
            {values.password !== "" && !errors.password ? (
              <Error
                touched={touched.confirmPassword}
                message={errors.confirmPassword}
              />
            ) : null}
          </div>

          {status?.message && (
            <div className="mt-4">
              <div
                className="flex space-x-2 mt-4 w-full p-3 px-4 items-center"
                style={{
                  backgroundColor: "#FAEDE9",
                }}
              >
                <img src={ErrorAltIcon} alt="oops!" />
                <div className="text-black-400 text-sm">
                  {withErrorHandlerComponent(status?.message)}
                </div>
              </div>
            </div>
          )}

          <button
            className="submitButton newButton w-full h-13"
            type="submit"
            disabled={isSubmitting}
          >
            SAVE
          </button>
          <div className="flex flex-col text-center mt-1">
            {generatedPassword?.length > 0 ? (
              <>
                <span className="text-xs text-black-400">
                  Suggested Password:{" "}
                </span>
                <span className="font-bold text-md text-green-900 mt-2">
                  <Tooltip title="click here to copy">
                    <div
                      className="submitButton newSignUp h-13 "
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "0px",
                      }}
                      onClick={() => copyToClipboard(generatedPassword)}
                    >
                      {generatedPassword}
                    </div>
                  </Tooltip>

                  {copy ? (
                    <span className="text-xs font-normal text-black-400 absolute bg-grey-200 px-2 py-2 -mt-6 ml-2">
                      Copied
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </>
            ) : null}
            <span
              className="text-md mt-2 cursor-pointer underline text-blue-500"
              onClick={reGeneratePassword}
            >
              Generate Password
            </span>
            {false ? (
              <span className="text-xs text-black-400 mt-2 ml-3 text-left">
                <ul className="list-decimal">
                  <li>Please copy this password for further use.</li>
                  <li>You can also set your own password.</li>
                </ul>
              </span>
            ) : null}
          </div>
          <div className="pb-4 mb-4">{validationMessage}</div>
          <div className="text-center py-4 text-black-400">
            Back to Login?
            <span
              className="text-blue-500 cursor-pointer ml-1"
              onClick={() => location.reload()}
            >
              Click Here
            </span>
          </div>
        </div>
      </div>
    </BottomModal>
  );
};
export default createPassword;
