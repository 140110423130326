import React from "react";
import "./PasswordValidation.css";

export const validationMessage = (
  <div
    id="message"
    className="relative message text-left  font-roboto font-regular text-sm text-grey-1100"
  >
    <p id="letter" className="invalid ">
      A lowercase letter
    </p>
    <p id="capital" className="invalid">
      A capital (uppercase) letter
    </p>
    <p id="number" className="invalid">
      A number
    </p>
    <p id="length" className="invalid">
      Password should be [8-15] character long
    </p>
    <p id="specialChar" className="invalid capitalize">
      Minimum one special character
    </p>
  </div>
);

export const passwordValidation = () => {
  //passwordValidation msg
  var myInput = document.getElementById("password");
  var letter = document.getElementById("letter");
  var capital = document.getElementById("capital");
  var number = document.getElementById("number");
  var length = document.getElementById("length");
  var specialcharacter = document.getElementById("specialChar");
  myInput.onfocus = function () {
    document.getElementById("message").style.display = "block";
  };
  // When the user clicks outside of the password field, hide the message box
  myInput.onblur = function () {
    document.getElementById("message").style.display = "none";
  };

  // When the user starts to type something inside the password field
  myInput.onkeyup = function () {
    // Validate lowercase letters
    document.getElementById("message").style.display = "block";
    var lowerCaseLetters = /[a-z]/g;
    if (myInput.value.match(lowerCaseLetters)) {
      letter.classList.remove("invalid");
      letter.classList.add("valid");
    } else {
      letter.classList.remove("valid");
      letter.classList.add("invalid");
    }

    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if (myInput.value.match(upperCaseLetters)) {
      capital.classList.remove("invalid");
      capital.classList.add("valid");
    } else {
      capital.classList.remove("valid");
      capital.classList.add("invalid");
    }

    // Validate numbers
    var numbers = /[0-9]/g;
    if (myInput.value.match(numbers)) {
      number.classList.remove("invalid");
      number.classList.add("valid");
    } else {
      number.classList.remove("valid");
      number.classList.add("invalid");
    }

    // Validate length
    if (myInput.value.length >= 8 && myInput.value.length <= 15) {
      length.classList.remove("invalid");
      length.classList.add("valid");
    } else {
      length.classList.remove("valid");
      length.classList.add("invalid");
    }
    // Special character validation
    var specialcharacter = /[!@#$%^&*()\-_=+{};:,<.>]/g;
    if (myInput.value.match(specialcharacter)) {
      specialChar.classList.remove("invalid");
      specialChar.classList.add("valid");
    } else {
      specialChar.classList.remove("valid");
      specialChar.classList.add("invalid");
    }
  };
};
