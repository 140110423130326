import React from "react";

const Error = ({ touched, message }) => {
  if (touched && message) {
    return (
      <div className="  capitalize  mx-auto text-red-100 text-sm font-roboto">
        {message}
      </div>
    );
  }
  return null;
};
export default Error;
